<script>
import { defineComponent, ref, watch } from 'vue';
import { Option, Select, Table, TableColumn } from 'element-ui'
import serverSidePaginationMixin from '../Tables/PaginatedTables/serverSidePaginationMixin'
import 'sweetalert2/src/sweetalert2.scss'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import moment from 'moment'
import BaseSwitch from '@/components/BaseSwitch.vue';
let timeout = null

export default defineComponent({
  name: 'AddonsComponent',
  mixins: [serverSidePaginationMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    // ElOption, ElSelect, ElTable, ElTableColumn, 
    BaseSwitch
  },
  data() {
    return {
      loading: false,
      searchQuery: '',
      filters: {
        status: 'Active',
      },
      status: [
        {
          label: 'Active',
          value: 'Active',
        },
        {
          label: 'Inactive',
          value: 'Inactive',
        },
        {
          label: 'Show All',
          value: '',
        },
      ],
      tableData: [],
    }
  },
  watch: {
    searchQuery: function () {
      clearTimeout(timeout)
      timeout = setTimeout(() => {
        this.fetchData()
      }, 300)
    },
  },
  mounted: function () {
    if (localStorage.getItem('hot-token')) {
      this.hot_user = localStorage.getItem('hot-user')
      this.hot_token = localStorage.getItem('hot-token')
    }

    this.setDefaultFilterData()
  },
  methods: {
    fetchData() {
      /*To get all the addons*/
      this.loading = true
      this.$http
    .get('/addon_add/list', {
      params: {
        search: this.searchQuery,
        page: this.currentPage,
        per_page: this.perPage,
        status: this.filters.status,
      },
    })
        .then((response) => {
          this.tableData = []
         
          this.tableData = response.data.data
          this.totalData = response.data?.total
       
        })
        .finally(() => (this.loading = false))
      this.saveSearchData()
    },
    resetFilters() {
      this.searchQuery = ''
      this.filters.status = 'Active'
      this.fetchData()
    },
    deleteAddon(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .delete('addon_add/delete/' + id)
              .then((resp) => {
                this.fetchData()
                Swal.fire({
                  title: 'Deleted!',
                  text: 'Addon has been deleted.',
                  icon: 'success',
                  confirmButtonClass: 'btn btn-success btn-fill',
                  buttonsStyling: false,
                }).then(function () {})
              })
          }
        })
        .catch(function () {})
    },
    saveSearchData() {
      localStorage.setItem(
        'addons-filters',
        JSON.stringify({
          role: 'super-admin',
          search: this.searchQuery,
          status: this.filters.status,
          page: this.currentPage,
          per_page: this.perPage,
        })
      )
    },
    setDefaultFilterData() {
      let previousStateData = JSON.parse(
        localStorage.getItem('addons-filters')
      )
      if (previousStateData !== null) {
        this.searchQuery =
          previousStateData.search != undefined
            ? previousStateData.search
            : this.searchQuery
        this.filters.status =
          previousStateData.status != undefined
            ? previousStateData.status
            : this.filters.status
        this.perPage =
          previousStateData.per_page != undefined
            ? previousStateData.per_page
            : this.perPage
      }

      this.fetchData()
    },

    changeStatus(index, row) {

      if (!row || !row.id) {
       console.error('Invalid row data');
       return;
      }


      let prev_val = row.status
      let status = ''
      if (prev_val) {
        status = 0
      } else {
        status = 1
      }
      let self = this
      Swal.fire({
        title: 'Are you sure?',
        text: 'You want to change status!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-success btn-fill',
        cancelButtonClass: 'btn btn-danger btn-fill',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        buttonsStyling: false,
      })
        .then((result) => {
          if (result.value) {
            this.$http
              .post(
                '/addon_add/status/' + row.id,
                {
                  status: status,
                }
                // self.config
              )
              .then((resp) => {
                this.fetchData()
                Swal.fire({
                  title: 'Success!',
                  text: 'Status has been Changed.',
                  icon: 'success',
                  confirmButtonClass: 'btn btn-success btn-fill',
                  buttonsStyling: false,
                })
                this.tableData[index].status = !prev_val
              })
          } else {
            this.tableData[index].status = prev_val
          }
        })
        .catch(function () {
          this.tableData[index].status = prev_val
        })
    },
    changeIsAdditionalStatus(index, row) {

if (!row || !row.id) {
 console.error('Invalid row data');
 return;
}


let prev_val = row.status
let status = ''
if (prev_val) {
  status = 0
} else {
  status = 1
}
let self = this
Swal.fire({
  title: 'Are you sure?',
  text: 'You want to change status!',
  icon: 'warning',
  showCancelButton: true,
  confirmButtonClass: 'btn btn-success btn-fill',
  cancelButtonClass: 'btn btn-danger btn-fill',
  confirmButtonText: 'Yes',
  cancelButtonText: 'No',
  buttonsStyling: false,
})
  .then((result) => {
    if (result.value) {
      this.$http
        .post(
          '/addon_add/additional/' + row.id,
          {
            is_additional: status,
          }
          // self.config
        )
        .then((resp) => {
          this.fetchData()
          Swal.fire({
            title: 'Success!',
            text: 'Is Additional Status has been Changed.',
            icon: 'success',
            confirmButtonClass: 'btn btn-success btn-fill',
            buttonsStyling: false,
          })
          this.tableData[index].status = !prev_val
        })
    } else {
      this.tableData[index].status = prev_val
    }
  })
  .catch(function () {
    this.tableData[index].status = prev_val
  })
},
  },
})
</script>
<template>
  <div>
    <div class="content">
      <div class="container-fluid mt-3">
        <card
          class="card no-border-card"
          footer-classes="pb-2"
          v-loading.fullscreen.lock="loading"
        >
        
            <div class="card-header">
            <div class="row align-items-center">
              <div class="col-lg-6 col-md-6 col-12">
                <h2 class="mb-0">Addons</h2>
              </div>
              <div class="col-lg-6 col-md-6 text-right">
                <base-button class="custom-btn" v-on:click="resetFilters()"
                  ><i aria-hidden="true" class="fa fa-refresh"></i> Clear
                  Filters</base-button
                >
                <router-link to="/create_addons">
                  <base-button class="custom-btn"
                    ><i aria-hidden="true" class="fa fa-plus"></i> Add Addon</base-button
                  >
                </router-link>
              </div>
            </div>
          </div>
         
          <div class="card-body">
          <div>
            <div class="row flex-wrap">
              <div class="col-md">
                <base-input
                  label="Search:"
                  placeholder="Search..."
                  prepend-icon="fas fa-search"
                  v-model="searchQuery"
                ></base-input>
              </div>
              <div class="col-md">
                <base-input label="Status:">
                  <el-select
                    class="select-primary"
                    v-on:change="changePage(1)"
                    v-model="filters.status"
                    placeholder="Filter by Company Status"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in status"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-md">
                <base-input label="Showing:">
                  <el-select
                    class="select-primary pagination-select"
                    placeholder="Per page"
                    v-model="perPage"
                    v-on:change="changePage(1)"
                  >
                    <el-option
                      :key="item"
                      :label="item"
                      :value="item"
                      class="select-primary"
                      v-for="item in perPageOptions"
                    ></el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="user-eltable">
              <el-table
                v-if="tableData.length"
                :data="tableData"
                class="compGrid"
                header-row-class-name="thead-light"
                highlight-current-row
                id="tbl1"
                role="table"
                row-key="id"
                stripe
              >
              <el-table-column label="Icon Image" min-width="150px" prop="title">
                  <template v-slot="props">
                    <img :src="props.row && props.row.icon" style="width: 30px; height: 30px" />
                  </template>
              </el-table-column
                >
                <el-table-column label="Title" min-width="150px" prop="title">
                  <template v-slot="props">
                    <span> {{ props.row && props.row.title }}</span>
                  </template></el-table-column
                >
                <el-table-column label="Price" min-width="150px" prop="title">
                  <template v-slot="props">
                    <span> {{ props.row && `${props.row.currency} ${props.row.price}` }}</span>
                  </template></el-table-column
                >
                <el-table-column label="Duration" min-width="150px" prop="title">
                  <template v-slot="props">
                    <span> {{ props.row && props.row.durations }}</span>
                  </template></el-table-column
                >
                <el-table-column
                  label="Created On"
                  min-width="100px"
                  prop="created_at"
                >
                  <template v-slot="props">
                    <span>{{ props.row && props.row.created_at }}</span>
                  </template>
                </el-table-column>
           
                <el-table-column label="Status" min-width="100px">
                  <template v-slot="props">
                    <div
                      class="d-flex"
                      v-on:click="changeStatus(props.$index, props.row)"
                    >
                      <BaseSwitch
                    class="mr-1"
                  :type="props.row.status ? 'success' : 'danger'"
                  v-model:value="props.row.status"
                ></BaseSwitch>
                    </div>
                  </template>
                </el-table-column>

                <el-table-column label="Is Additional" min-width="100px">
                  <template v-slot="props">
                    <div
                      class="d-flex"
                      v-on:click="changeIsAdditionalStatus(props.$index, props.row)"
                    >
                      <BaseSwitch
                    class="mr-1"
                  :type="props.row.is_additional ? 'success' : 'danger'"
                  v-model:value="props.row.is_additional"
                ></BaseSwitch>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column align="left" label="Actions" min-width="100px">
                  <template v-slot="props">
                  <div class="d-flex custom-size" >
                    <el-tooltip content="Edit" placement="top">
                      <router-link v-if="props.row" :to="'/create_addons?id=' + props.row.id">
                        <base-button
                          class="success"
                          type=""
                          size="sm"
                          icon
                          data-toggle="tooltip"
                          data-original-title="Edit"
                        >
                          <i class="text-default fa fa-pencil-square-o"></i>
                        </base-button>
                      </router-link>
                    </el-tooltip>
                    <el-tooltip content="Delete" placement="top">
                      <base-button
                        @click="deleteAddon(props.row.id)"
                        type=""
                        size="sm"
                        icon
                        data-toggle="tooltip"
                        data-original-title="Delete"
                      >
                        <i class="text-danger fa fa-trash"></i>
                      </base-button>
                    </el-tooltip>
                  </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="d-flex justify-content-end" slot="footer">
            <nav v-if="pagination && tableData.length > 0">
              <div class="row">
                <div class="col-md-12">
                  <ul
                    class="pagination custompagination justify-content-end align-items-center"
                  >
                    <p class="p-0 m-0 mr-2">
                      Showing {{ tableData.length }} of {{ totalData }} entries
                    </p>
                    <li
                      :class="{ disabled: currentPage === 1 }"
                      class="page-item"
                    >
                      <a
                        @click.prevent="changePage(currentPage - 1)"
                        class="page-link"
                        href="#"
                        ><i class="fa fa-caret-left"></i
                      ></a>
                    </li>
                    <li
                      :class="{ active: page == currentPage }"
                      class="page-item"
                      v-bind:key="index"
                      v-for="(page, index) in pagesNumber"
                    >
                      <a
                        @click.prevent="changePage(page)"
                        class="page-link"
                        href="javascript:void(0)"
                        >{{ page }}</a
                      >
                    </li>
                    <li
                      :class="{ disabled: currentPage === last_page }"
                      class="page-item"
                    >
                      <a
                        @click.prevent="changePage(currentPage + 1)"
                        class="page-link"
                        href="#"
                        ><i class="fa fa-caret-right"></i
                      ></a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
