<script>
import { defineComponent, ref, watch } from 'vue';
import {
  DatePicker,
  TimeSelect,
  // Table,
  TableColumn,
  Select,
  Option,
} from 'element-ui';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import FileInput from '@/components/Inputs/FileInput.vue';

export default defineComponent({
  name: "CreateAddons",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    // [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    FileInput
  },
  data() {
    return {
      loading: false,
      edit: '',
      image: '',
      baseUrl: this.$baseUrl,
      addonForm: {
        add_name: '',
        price: '',
        duration: '',
        description: '',
        status: true,
        is_additional: false
      },
      durations: [
        { id: 1, name: 'Monthly', value: 'monthly' },
        { id: 2, name: 'Yearly', value: 'yearly' },
        { id: 3, name: 'Per User', value: 'peruser' }
      ]
    }
  },
  created() {
    if (this.$route.query.id) {
      this.edit = this.$route.query.id;

      this.$http
        .get('addon_add/details/' + this.edit)
        .then((resp) => {
          let data = resp.data.data;
          this.addonForm.add_name = data?.title;
          this.addonForm.price = Number(data?.price);
          this.addonForm.duration = data?.durations;
          this.addonForm.description = data?.descriptions;
          this.addonForm.status = data.status === 1 ? true : false;
          this.addonForm.is_additional = data.is_additional === 1 ? true : false;
          this.image = data?.icon
        })
    }
  },
  methods: {
    onImageChange(e) {
      let files = e;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    isDecimal(value) {
    return /^\d+(\.\d{1,2})?$/.test(value);
  },
    saveAddon() {
      this.$refs.formValidator.validate().then(success => {
        if (success) {
          const payload = {
            title: this.addonForm.add_name,
            icon: this.image,
            price: this.addonForm.price,
            currency: '$',
            durations: this.addonForm.duration,
            status: this.addonForm.status ? 1 : 0,
            is_additional: this.addonForm.is_additional ? 1 : 0,
            descriptions: this.addonForm.description
          };

          if (this.edit !== '') {
            this.loading = true;
            this.$http
              .post('addon_add/edit/' + this.edit, payload)
              .then((resp) => {
                Swal.fire({
                  title: 'Success!',
                  text: 'Addon updated successfully.',
                  icon: 'success',
                });
                this.$router.push('/addons');
              })
              .finally(() => (this.loading = false));
          } else {
            this.loading = true;
            this.$http
              .post('addon_add/add', payload)
              .then((resp) => {
                Swal.fire({
                  title: 'Success!',
                  text: 'Addon saved successfully.',
                  icon: 'success',
                });
                this.$router.push('/addons');
              })
              .finally(() => (this.loading = false));
          }
        } else {
          Swal.fire({
            title: 'Error!',
            text: `Please fill all required fields.`,
            icon: 'error',
          });
        }
      });
    }
  }
});
</script>

<template>
  <div class="content" v-loading.fullscreen.lock="loading">
    <base-header class="pb-6">
      <div class="row align-items-center py-2">
        <div class="col-lg-6 col-7"></div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <card class="card no-border-card">
        <div class="card-header">
          <h2 slot="header" class="mb-0" v-if="edit">Edit Addon</h2>
          <h2 slot="header" class="mb-0" v-else>Add Addon</h2>
        </div>
        <div class="card-body">
          <validation-observer ref="formValidator" v-slot="{ handleSubmit, invalid }">
            <form
              class="needs-validation"
              @submit.prevent="handleSubmit(saveAddon)"
              enctype="multipart/form-data"
            >
              <div class="form-row">
                <div class="col-md-6">
                  <label class="form-control-label">
                    Name <span class="requireField">*</span>
                  </label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <base-input
                      name="addon name"
                      placeholder="name"
                      v-model="addonForm.add_name"
                    />
                    <span class="text-danger">{{ errors[0] ? 'Name field is required' : '' }}</span>
                  </validation-provider>
                </div>

                <div class="col-md-6">
                  <label class="form-control-label">
                    Price <span class="requireField">*</span>
                  </label>
                  <validation-provider rules="required" v-slot="{ errors }">
                  <!-- <validation-provider rules="required|numeric" v-slot="{ errors }"> -->
                    <base-input
                      name="price"
                      type="text"
                     
                      placeholder="price"
                      v-model="addonForm.price"
                    />
                    <span class="text-danger">{{ errors[0] ? 'Price field is required' : '' }}</span>
                  </validation-provider>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-4">
                  <label class="form-control-label">Logo</label>
                  <form>
                    <file-input v-on:change="onImageChange"></file-input>
                  </form>
                </div>
                <div class="col-md-2" v-if="image">
                <img
                  class="logo-size mt-5"
                  :src="`${image}`"
                  style="cursor: pointer"
                />
              </div>
                
                <div class="col-md-6">
                  <label class="form-control-label">Duration <span class="requireField">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <select
                      class="form-control"
                      v-model="addonForm.duration"
                      required
                    >
                      <option value="" disabled>Select Duration*</option>
                      <option
                        v-for="(duration, index) in durations"
                        :value="duration.value"
                        :key="duration.id"
                      >
                        {{ duration.name }}
                      </option>
                    </select>
                    <span class="text-danger">{{ errors[0] ? 'Duration field is required' : '' }}</span>
                  </validation-provider>
                </div>
              </div>

              <div class="form-row">
                <div class="col-md-6">
                  <label class="form-control-label">Description <span class="requireField">*</span></label>
                  <validation-provider rules="required" v-slot="{ errors }">
                    <textarea
                      placeholder="description"
                      class="form-control"
                      v-model="addonForm.description"
                      required
                    ></textarea>
                    <span class="text-danger">{{ errors[0] ? 'Description field is required' : '' }}</span>
                  </validation-provider>
                </div>
                <div class="col-md-3">
                  <label class="form-control-label">Status</label>
                  <div class="d-flex">
                    <base-switch
                      class="mr-1"
                      type="success"
                      v-model="addonForm.status"
                    ></base-switch>
                  </div>
                </div>
                <div class="col-md-3">
                  <label class="form-control-label">Is Additional</label>
                  <div class="d-flex">
                    <base-switch
                      class="mr-1"
                      type="success"
                      v-model="addonForm.is_additional"
                    ></base-switch>
                  </div>
                </div>
              </div>

              <div class="text-right mt-2">
                <base-button
                  class="custom-btn"
                  type="danger"
                  @click="$router.go(-1)"
                >
                  Cancel
                </base-button>
                <base-button class="custom-btn" native-type="submit">
                  {{ edit !== '' ? 'Update' : 'Submit' }}
                </base-button>
              </div>
            </form>
          </validation-observer>
        </div>
      </card>
    </div>
  </div>
</template>

<style scoped>
.text-danger {
  width: 100% !important;
  margin-top: 0.25rem !important;
  font-size: 80% !important;
  color: #fb6340 !important;
}
</style>






